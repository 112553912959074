<template>
  <Head title="Willkommen beim Tippspiel" />

  <GuestLayout>
    <div class="flex flex-col gap-4">
      <div class="flex items-center">
        <AppHeadline level="h1">EM 2024 Tippspiel - Mitfiebern und Gutes tun!</AppHeadline>
        <ApplicationLogo class="w-40" />
      </div>
      <p>
        Willkommen zu unserem Tippspiel für die EM 2024! Hier kannst du dein Fußballwissen testen oder dich mit einem
        Quicktipp ganz aufs Glück verlassen. Nicht nur, wer richtig tippt gewinnt: Dein Tippeinsatz unterstützt
        gleichzeitzig den Kinder- und Jugendfußball.
      </p>
      <div class="my-6 px-10">
        <p>In zwei Schritten zum vollständig ausgefüllten Tippschein.</p>
        <ul class="list-inside list-disc">
          <li><AppLink :to="route('register')">Registrieren</AppLink></li>
          <li class="cursor-pointer underline" @click.prevent="handleQuicktipBoxClick">Quicktipp verwenden</li>
        </ul>
        <p class="mt-2">
          Optional kann der Tippschein noch angepasst werden und schon kann man den Nachwuchs unterstützen.
        </p>
        <div class="mt-4 text-center">
          <AppLink :to="route('register')">
            <Button label="Jetzt starten" />
          </AppLink>
        </div>
      </div>
      <AppHeadline class="!my-0" level="h3">Was ist unser EM 2024 Tippspiel?</AppHeadline>
      <p>
        Unser EM 2024 Tippspiel ist eine unterhaltsame Möglichkeit, die Europameisterschaft noch spannender zu
        gestalten. Du tippst auf die Ergebnisse der Spiele und sammelst Punkte für jede richtige Vorhersage - ob
        Endstand oder Ergebnistrend. Mit etwas Glück und Geschick gewinnst du tolle Bargeldpreise!
      </p>
      <p>Weitere Infos findest du unter den <AppLink :to="route('rules')">Spielregeln</AppLink></p>
    </div>

    <div class="mt-4">
      <div class="grid grid-cols-1 gap-6 md:grid-cols-3 lg:gap-8">
        <div
          class="duration-250 flex scale-100 cursor-pointer rounded-lg bg-white from-gray-700/50 via-transparent p-6 shadow-2xl shadow-gray-500/20 transition-all focus:outline focus:outline-2 focus:outline-red-500 motion-safe:hover:scale-[1.01]"
          @click="handleQuicktipBoxClick()"
        >
          <div>
            <AppHeadline class="!mt-0" level="h4">Quicktipp</AppHeadline>

            <p class="mt-4 text-sm leading-relaxed text-gray-500">
              Keine Idee von Fußball? Mit einem Klick werden alle Ergebnisse anhand der aktuellen Wettquoten ausgefüllt.
            </p>
          </div>
        </div>

        <div
          class="duration-250 flex scale-100 cursor-pointer rounded-lg bg-white from-gray-700/50 via-transparent p-6 shadow-2xl shadow-gray-500/20 transition-all focus:outline focus:outline-2 focus:outline-red-500 motion-safe:hover:scale-[1.01]"
          @click="handleRegisterBoxClick"
        >
          <div>
            <AppHeadline class="!mt-0" level="h4">Kosten</AppHeadline>

            <p class="mt-4 text-sm leading-relaxed text-gray-500">5€ pro Tippschein</p>
            <p class="mt-4 text-sm leading-relaxed text-gray-500">Jetzt Registrieren und loslegen!</p>
          </div>
        </div>

        <div
          class="duration-250 flex scale-100 cursor-pointer rounded-lg bg-white from-gray-700/50 via-transparent p-6 shadow-2xl shadow-gray-500/20 transition-all focus:outline focus:outline-2 focus:outline-red-500 motion-safe:hover:scale-[1.01]"
          @click="handleResultBoxClick"
        >
          <div>
            <AppHeadline class="!mt-0" level="h4">Ergebnisübersicht</AppHeadline>

            <p class="mt-4 text-sm leading-relaxed text-gray-500">
              Die Ergebnisse und Punktezwischenstände des Gewinnspiels sind immer aktuell
            </p>
          </div>
        </div>
      </div>
      <PublicViewing class="mt-8" />
    </div>
  </GuestLayout>
</template>

<script setup lang="ts">
import AppHeadline from '@/Components/AppHeadline.vue';
import AppLink from '@/Components/AppLink.vue';
import ApplicationLogo from '@/Components/ApplicationLogo.vue';
import PublicViewing from '@/Components/PublicViewing.vue';
import GuestLayout from '@/Layouts/GuestLayout.vue';
import Button from 'primevue/button';
import { router, Head, usePage } from '@inertiajs/vue3';
import { computed } from 'vue';
import { route } from 'ziggy-js';

const page = usePage();
const isLoggedIn = computed(() => !!page.props.auth.user);

function handleResultBoxClick(): void {
  router.visit(route('statistic.index'));
}

function handleRegisterBoxClick(): void {
  router.visit(route('register'));
}

function handleQuicktipBoxClick(): void {
  if (isLoggedIn.value === true) {
    router.post(route('quicktip'));

    return;
  }

  router.visit(route('register'));
}
</script>

<style></style>
